<template>
  <div class="bg-white flex flex-col justify-center items-center px-4 py-10 rounded-md">
    <div class="flex flex-col gap-2">
      <CardTitleHeaderForMobileView
          :company-name="currentCompanyDetails?.name"
          :company-logo="currentCompanyDetails?.logo"
          :has-description="true"
          :without-welcome-to="true"
          class=""
      />
    </div>
    <div v-if="currentLoginView == FeedPageGuestLoginView.LoginWithGoogle"
         class="flex flex-col items-center justify-center gap-4 mt-6"
    >
      <div v-if="showSignupLink">
        <span v-if="selectedContinueMode == ContinueMode.Login" class="font-semibold text-gray-500">Welcome Back!</span>
        <span v-else-if="selectedContinueMode == ContinueMode.Signup" class="font-semibold text-gray-500">Sign up with one of these</span>
      </div>
      <div class="flex flex-row items-center justify-center gap-2">
        <LoadingCircle v-if="googleSignInLoadingState" size="5"/>
        <GButtonCustom width="w-[300px]" @onGoogleSignInSuccess="onGoogleSignInSuccess"
                       alignment="items-start"/>
      </div>
      <div class="flex flex-row items-center justify-center gap-2" v-if="false">
        <LoadingCircle size="5" v-if="microsoftSignInLoadingState"/>
        <MSAuthButton class="w-[250px] outline outline-1 outline-gray-300 rounded-sm hover:bg-gray-200 bg-white"
                      @onAuthenticate="onMicrosoftSignInSuccess"
        />
      </div>

      <button type="button"
              class="w-[300px] flex bg-gray-200 text-gray-900 rounded-full"
              @click="showContinueWithOther()"
      >
                  <span class="flex flex-row items-center justify-start p-2 gap-2 w-full">
                    <Icon class="h-6 w-6"
                          name="material-symbols-light:mail"
                    />
                    <span class="flex flex-1 items-center justify-center">
                      <span class="font-medium text-gray-700">{{ ('Continue with E-mail') }}</span>
                    </span>
                  </span>
      </button>
      <div v-if="showSignupLink">
        <span v-if="selectedContinueMode == ContinueMode.Login" class="text-sm text-gray-500 flex flex-row items-center justify-center gap-2">Don't have an account? <button
            class="text-brand-color" type="button" @click="onSignupPressed" :disabled="modeChanging">Sign Up</button> <LoadingCircle size="5" v-if="modeChanging"/> </span>
        <span v-else-if="selectedContinueMode == ContinueMode.Signup" class="text-sm text-gray-500 flex flex-row items-center justify-center gap-2">Already have an account? <button
            class="text-brand-color" type="button" @click="onLoginPressed" :disabled="modeChanging">Login</button> <LoadingCircle size="5" v-if="modeChanging"/> </span>
      </div>
      <!--      <div v-if="showSignupLink && hasModule(companyDetails, CompanyModules.Parent)"-->
      <!--           class="flex flex-row gap-1 mt-5 text-sm">-->
      <!--        <span class="text-gray-500">Not registered yet?</span>-->
      <!--        <button class="text-brand-color" type="button" @click="goToSignupPage">Sign up here!</button>-->
      <!--      </div>-->
    </div>
    <div v-else-if="currentLoginView == FeedPageGuestLoginView.LoginWithEmail" class="flex w-full">
      <div class="bg-white p-4 w-full rounded-md">
        <div class="w-full sm:flex sm:items-start md:items-center justify-center">
          <div class="w-full mt-2 text-center sm:mt-0 sm:text-left flex flex-col items-center justify-center">
            <div class="w-full md:w-1/2">
              <div class="sm:flex sm:flex-row-reverse justify-center">
                <form class="w-full" @submit.prevent="validateAndSubmitForm">
                  <div class="flex flex-col  w-full">
                    <div class="flex flex-col w-full mt-2 gap-2">
                      <label
                          class="block text-start  font-medium leading-6 text-gray-700 min-w-[170px]"
                      >
                        {{
                          ('Email')
                        }}
                        <span class="text-red-500">*</span>
                      </label>
                      <div class="flex rounded-md w-full">
                        <input type="email" v-model="email"
                               :placeholder="('Enter Your Email Address')"
                               class="w-full rounded-md border-gray-300 block border-1 border-solid bg-transparent py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-1  focus:ring-brand-color  sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div class="w-full my-5 flex flex-row gap-2">
                      <div @click="showContinueWithGoogle"
                           class="cursor-pointer flex items-center justify-center py-2 bg-gray-200 rounded-md w-10"
                      >
                        <Icon class="text-gray-700 h-7 w-7 cursor-pointer"
                              name="ic:twotone-keyboard-arrow-left"/>
                      </div>
                      <button type="submit"
                              class="w-full flex flex-1 flex-row items-center gap-2 justify-center bg-brand-color text-white text-center rounded-md py-2 hover:scale-95 transition duration-400"
                              :disabled="emailViewLoadingState"
                              ref="cancelButtonRef">
                                                        <span v-if="emailViewLoadingState" role="status">
                                                            <LoadingCircle size="5"/>
                                                        </span>
                        <div>
                          <span class="py-4 text-[13px] ">{{ ('Continue') }}<Icon
                              name="ic:round-keyboard-arrow-right"/></span>

                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="currentLoginView == FeedPageGuestLoginView.LoginWithPassword" class="flex w-full">
      <div class="bg-white p-4 w-full rounded-md">
        <div class="w-full sm:flex sm:items-start md:items-center justify-center">
          <div class="w-full mt-2 text-center sm:mt-0 sm:text-left flex flex-col items-center justify-center">

            <div class="w-full md:w-1/2">
              <div class="sm:flex sm:flex-row-reverse justify-center">
                <form class="w-full"
                      @submit.prevent="validateAndLoginForm(email, password)">
                  <!--Send OTP button-->
                  <div class="mt-2 flex flex-col items-center space-y-2">
                    <button type="button"
                            @click="onSendOtpToEmail(email.toString())"
                            :disabled="sendOtpLoadingState"
                            class="w-full inline-flex items-center text-center content-center justify-center rounded-md px-3 py-1.5 text-sm leading-6 bg-brand-color outline outline-1 outline-brand-color shadow-md">
                      <LoadingCircle size="5" v-if="sendOtpLoadingState"/>
                      <span class="flex flex-col md:flex-row items-center space-x-0 lg:space-x-2 text-white">
                        <Icon name="ph:envelope-simple" class="h-4 w-4 text-white"/>
                        <span> {{('Send me a Code')}}
<!--                          <span class="text-xs lg:text-sm">{{getHiddenEmail(email.toString())}}</span>-->
                        </span>
                      </span>
                    </button>
                    <span class="text-xs text-center">*{{ (`A verification code will be sent to your ${getHiddenEmail(email.toString())} email`) }}</span>
                  </div>
                  <div class="relative my-4">
                    <div class="absolute inset-0 flex items-center"
                         aria-hidden="true">
                      <div class="w-full border-t border-gray-200"/>
                    </div>
                    <div class="relative flex justify-center text-sm leading-6">
                                                                    <span class="bg-white px-6 text-gray-400">{{
                                                                        ('Or')
                                                                      }}</span>
                    </div>
                  </div>
                  <!--Password fields-->
                  <div class="flex flex-col w-full">
                    <div class="flex flex-col w-full gap-2">
                      <label class="block text-start  font-medium leading-6 text-gray-700 min-w-[170px]">{{
                          ('Password')
                        }}
                        <span class="text-red-500">*</span>
                      </label>
                      <div class="flex rounded-md w-full relative">
                          <input :type="showPassword ? 'text' : 'password'" v-model="password"
                                placeholder="Enter Your Password"
                                autocomplete="current-password"
                                class="w-full px-4 rounded-md border-gray-300 block border-1 border-solid bg-transparent py-1 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-brand-color sm:text-sm sm:leading-6"
                                />
                          <button type="button" @click="togglePassword" class="absolute inset-y-0 right-0 py-1 pr-3 flex z-20 text-gray-400 focus:outline-none focus:text-brand-color">
                              <svg v-if="!showPassword" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6">
                                  <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                  <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                  <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                  <line x1="4" x2="20" y1="4" y2="20"></line>
                              </svg>
                              <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="h-6 w-6">
                                  <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                              </svg>
                          </button>
                      </div>
                    </div>
                    <div class="w-full my-5 flex flex-row gap-2">
                      <div @click="showContinueWithGoogle"
                           class="cursor-pointer flex items-center justify-center py-2 bg-gray-200 rounded-md w-10"
                      >
                        <Icon class="text-gray-700 h-7 w-7 cursor-pointer"
                              name="ic:twotone-keyboard-arrow-left"/>
                      </div>
                      <button type="submit"
                              class="w-full flex flex-1 flex-row items-center justify-center gap-2 bg-brand-color text-white text-center rounded-md py-2 hover:scale-95 transition duration-400"
                              :disabled="passwordViewLoadingState"
                              ref="cancelButtonRef">
                        <LoadingCircle size="5" v-if="passwordViewLoadingState"/>
                        <span>
                          <span class="py-4 text-[13px] ">{{ ('Continue') }}</span>
                          <Icon name="ic:round-keyboard-arrow-right"/>
                        </span>
                      </button>
                    </div>
                    <div class="flex flex-row justify-end">
                      <button type="button" class="text-sm underline text-gray-500 hover:text-gray-700" @click="onGoToResetPasswordState">Forgot password? Click to reset</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="currentLoginView == FeedPageGuestLoginView.LoginWithSignup" class="flex w-full">
      <div class="bg-white p-4 w-full rounded-md">
        <div class="w-full sm:flex sm:items-start md:items-center justify-center">
          <div class="w-full mt-2 text-center sm:mt-0 sm:text-left flex flex-col items-center justify-center">
            <div class="w-full">
              <div class="sm:flex sm:flex-row-reverse justify-center">
                <form class="w-full md:w-1/2"
                      @submit.prevent="validateAndSubmitSignupForm(true)">
                  <div class="flex flex-col  w-full">
                    <div class="flex flex-col w-full  mt-2">
                      <div class="flex flex-col md:flex-col w-full mt-2">
                        <label
                            class="block text-start text-sm font-medium leading-6 text-gray-700 min-w-[170px]">{{
                            ('First Name')
                          }}
                          <span class="text-red-500">*</span>
                        </label>
                        <div class="flex rounded-md w-full">
                          <input type="text" v-model="firstName"
                                 :placeholder="('Enter First Name')"
                                 class="w-full rounded-md border-gray-300 block border-1 border-solid bg-transparent py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-1  focus:ring-brand-color  sm:text-sm sm:leading-6"/>
                        </div>
                      </div>
                      <div class="flex flex-col w-full mt-2">
                        <label class="block text-start text-sm font-medium leading-6 text-gray-700 min-w-[170px]">{{
                            ('Last Name')
                          }}
                          <span class="text-red-500">*</span>
                        </label>
                        <div class="flex rounded-md w-full">
                          <input type="text" v-model="lastName"
                                 :placeholder="('Enter Last Name')"
                                 class="w-full rounded-md border-gray-300 block border-1 border-solid bg-transparent py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-1    focus:ring-brand-color sm:text-sm sm:leading-6"/>
                        </div>
                      </div>
                      <div class="flex flex-col w-full mt-2">
                        <label for="parent_phone" class="font-medium text-start block text-sm leading-6 text-gray-700">{{
                            ('Phone')
                          }} <span class="text-red-500">*</span></label>
                        <div class=" flex flex-row">
                          <span
                              class="bg-gray-100 inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">+1</span>
                          <input type="text" v-model="phone" v-maska data-maska="###-###-####"
                                 placeholder="###-###-####"
                                 class="block w-full rounded-r-md rounded-0 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-color sm:text-sm sm:leading-6"/>
                        </div>
                      </div>

                      <div class="flex flex-col w-full mt-2 ">
                        <label class="block text-start text-sm font-medium leading-6 text-gray-700 min-w-[170px]">{{
                            ('Password')
                          }}
                          <span class="text-red-500">*</span>
                        </label>
                        <div class="flex rounded-md w-full">
                          <input type="password" v-model="password"
                                 :placeholder="('Enter Password')"
                                 class="w-full rounded-md border-gray-300 block border-1 border-solid bg-transparent py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-brand-color sm:text-sm sm:leading-6"/>
                        </div>
                      </div>
                      <div class="flex flex-col w-full mt-2 ">
                        <label class="block text-start text-sm font-medium leading-6 text-gray-700 min-w-[170px]">{{
                            ('Confirm Password')
                          }}
                          <span class="text-red-500">*</span>
                        </label>
                        <div class="flex rounded-md w-full">
                          <input type="password" v-model="confirmPassword"
                                 :placeholder="('Confirm Password')"
                                 class="w-full rounded-md border-gray-300 block border-1 border-solid bg-transparent py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-1 focus:ring-brand-color sm:text-sm sm:leading-6"/>
                        </div>
                      </div>
                    </div>
                    <div class="w-full my-5 flex flex-row justify-end gap-2">
                      <div @click="showContinueWithGoogle"
                           class="cursor-pointer flex items-center justify-center py-2 bg-gray-200 rounded-md w-10"
                      >
                        <Icon class="text-gray-700 h-7 w-7 cursor-pointer"
                              name="ic:twotone-keyboard-arrow-left"/>
                      </div>
                      <button type="submit"
                              class="w-full flex flex-1 flex-row items-center justify-center gap-2 bg-brand-color text-white text-center rounded-md py-2 hover:scale-95 transition duration-400"
                              ref="cancelButtonRef">
                        <LoadingCircle size="5" v-if="signUpLoadingState"/>
                        <span>{{ ('Sign Up') }}</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="currentLoginView == FeedPageGuestLoginView.MatchOtp" class="flex w-full">
      <div class="bg-white p-4 w-full rounded-md">
        <div class="w-full sm:flex sm:items-start md:items-center justify-center">
          <div class="w-full mt-2 text-center sm:mt-0 sm:text-left flex flex-col items-center justify-center">
            <div class="w-full">
              <div class="flex  flex-col justify-center items-center">
                <div class="w-full md:w-1/2">
                  <form class="space-y-2 py-2"
                        @submit.prevent="validateAndSubmitOtpForm(otpRecipients.toString(), otp, otpType.toString())">
                    <div class="flex flex-col gap-y-4">
                      <div class="">
                        <label for="email"
                               class="block text-sm font-medium leading-6 text-gray-700">{{
                            ('Enter code')
                          }}*</label>
                        <div class="flex flex-row mt-2">
                          <input type="text" name="otp" v-model="otp" ref="otpField"
                                 id="otp" autocomplete="one-time-code"
                                 inputmode="numeric" pattern="[0-9]*"
                                 required
                                 class="block w-full rounded-md border-0 py-1.5 text-gray-700 shadow-sm ring-2 ring-inset ring-gray-300 placeholder:text-gray-400  focus:ring-brand-color  focus:ring-inset  sm:text-sm sm:leading-6 text-center"/>
                        </div>
                        <span class="text-xs text-center text-gray-500">{{ ('A code has been sent to') }} {{
                            otpType == 'email' ? getHiddenEmail(otpRecipients) : getHiddenPhone(otpRecipients.toString())
                          }}</span>
                      </div>
                      <div class="flex items-center justify-center">
                        <button type="button"
                                class="w-full text-center text-indigo-600 my-2 flex flex-row space-x-2 items-center justify-center"
                                :disabled="sendOtpDisabledState"
                                @click="onSendOtpToEmail(otpRecipients.toString(),otpType.toString(), true)">
                          <LoadingCircle size="5" v-if="resendOtpButtonLoadingState"/>
                          <Icon v-else name="ci:arrows-reload-01"/>
                          <span>{{ ('Resend Code') }}</span>
                        </button>
                      </div>
                      <div class="relative">
                        <div class="absolute inset-0 flex items-center"
                             aria-hidden="true">
                          <div class="w-full border-t border-gray-200"/>
                        </div>
                        <div class="relative flex justify-center text-sm leading-6">
                                                                    <span class="bg-white px-6 text-gray-400">{{
                                                                        ('Or')
                                                                      }}</span>
                        </div>
                      </div>
                      <div>
                        <button type="button"
                                @click="onSendOtpToEmail(otpType == 'email'? getPhoneWithCountryCode(phone.toString(),false,false) : (email.toString()),otpType == 'email'?'sms':'email')"
                                id="otpChange" :key="'OtpChange'"
                                :disabled="sendOtpDisabledState"
                                class=" code-for-otp w-full inline-flex items-center text-center content-center justify-center rounded-md px-3 py-1.5 text-sm leading-6 bg-gray-100 outline outline-2 outline-brand-color shadow-md">
                          <LoadingCircle size="5" v-if="sendOtpLoadingState"/>
                          <span class="flex flex-col lg:flex-row items-center space-x-0 lg:space-x-2">
                                             <span v-if="otpType == 'email'"><Icon name="material-symbols:sms-outline"
                                                                                   class="h-4 w-4 text-gray-500"/> {{
                                                 ('Text me the code at')
                                               }} <span
                                                   class="text-xs lg:text-sm">{{
                                                   getHiddenPhone(phone.toString())
                                                 }}</span>
                                             </span>
                                          <span v-else><Icon name="material-symbols:mail-outline-sharp"
                                                             class="h-4 w-4 text-gray-500"/> {{
                                              ('Email me the code at')
                                            }} <span
                                                class="text-xs lg:text-sm">{{
                                                getHiddenEmail(email.toString())
                                              }}</span>
                                             </span>
                                        </span>
                        </button>
                      </div>
                      <div class="mt-4 flex flex-col items-center justify-center w-full">
                        <div class="flex flex-row justify-center gap-2 w-full">
                          <div @click="showContinueWithGoogle"
                               class="cursor-pointer flex items-center justify-center py-2 bg-gray-200 rounded-md w-10">
                            <Icon class="text-gray-700 h-7 w-7 cursor-pointer"
                                  name="ic:twotone-keyboard-arrow-left"/>
                          </div>
                          <button type="submit"
                                  :disabled="matchingOtpState"
                                  class="w-full flex-1 flex flex-row items-center gap-2 text-center content-center justify-center rounded-md bg-brand-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 hover:scale-95 transition duration-400">
                            <LoadingCircle size="5" v-if="matchingOtpState"/>
                            <div>
                              <span class="py-4 text-[13px] ">{{ ('Continue') }}</span>
                              <Icon name="ic:round-keyboard-arrow-right"/>
                            </div>
                          </button>
                        </div>

                      </div>
                    </div>

                  </form>


                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="currentLoginView == FeedPageGuestLoginView.ResetPasswordEmail" class="flex w-full">
      <div class="bg-white p-4 w-full rounded-md">
        <div class="w-full sm:flex sm:items-start md:items-center justify-center">
          <div class="w-full mt-2 text-center sm:mt-0 sm:text-left flex flex-col items-center justify-center">
            <div class="w-full">
              <div class="flex  flex-col justify-center items-center">
                <div class="w-full md:w-1/2">
                  <div class="flex flex-col">
                    <span class="font-medium text-lg text-gray-700 text-center">{{ ('Reset Password') }}</span>
                    <button type="button" @click="goBackToEnterEmailState" class="text-gray-500"><u>{{
                        ('Use a different account')
                      }}</u></button>
                  </div>
                  <form class="w-full" @submit.prevent="validateAndSubmitResetPasswordForm(email.toString())">
                    <div class="flex flex-col  w-full">
                      <div class="flex flex-col w-full mt-2 gap-2">
                        <label
                            class="block text-start  font-medium leading-6 text-gray-700 min-w-[170px]"
                        >
                          {{
                            ('Email')
                          }}
                          <span class="text-red-500">*</span>
                        </label>
                        <div class="flex rounded-md w-full">
                          <input type="email" v-model="email"
                                 :placeholder="('Enter Your Email Address')"
                                 class="w-full rounded-md border-gray-300 block border-1 border-solid bg-transparent py-1.5 px-4 text-gray-900 placeholder:text-gray-400 focus:ring-1  focus:ring-brand-color  sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div class="w-full my-5 flex flex-row gap-2">
                        <div @click="showContinueWithGoogle"
                             class="cursor-pointer flex items-center justify-center py-2 bg-gray-200 rounded-md w-10"
                        >
                          <Icon class="text-gray-700 h-7 w-7 cursor-pointer"
                                name="ic:twotone-keyboard-arrow-left"/>
                        </div>
                        <button type="submit"
                                class="w-full flex flex-1 flex-row items-center gap-2 justify-center bg-brand-color text-white text-center rounded-md py-2 hover:scale-95 transition duration-400"
                                :disabled="resetPasswordLoadingState"
                                ref="cancelButtonRef">
                          <span v-if="resetPasswordLoadingState" role="status">
                              <LoadingCircle size="5"/>
                          </span>
                          <div>
                          <span class="py-4 text-[13px] ">{{ ('Continue') }}<Icon
                              name="ic:round-keyboard-arrow-right"/></span>

                          </div>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="currentLoginView == FeedPageGuestLoginView.ResetPasswordSuccess" class="flex w-full">
      <div class="bg-white p-4 w-full rounded-md">
        <div class="w-full sm:flex sm:items-start md:items-center justify-center">
          <div class="w-full mt-2 text-center sm:mt-0 sm:text-left flex flex-col items-center justify-center">
            <div class="w-full">
              <div class="flex  flex-col justify-center items-center">
                <div class="w-full md:w-1/2">
                  <div class="flex flex-col items-center justify-center">
                    <div class="flex flex-col justify-center items-center space-x-0 lg:space-x-2">
                      <span class="text-gray-500 text-center">{{ ('An email has been sent to you with the reset password link.') }}</span>
                      <span class="text-gray-500 text-center">{{ ('Please check your email') }}</span>
                    </div>
                    <div class="w-full my-5 flex flex-row gap-2">
                      <button type="button"
                              @click="goBackToEnterEmailState"
                              class="w-full flex flex-1 flex-row items-center gap-2 justify-center bg-brand-color text-white text-center rounded-md py-2 hover:scale-95 transition duration-400"
                              ref="cancelButtonRef">
                        <div>
                          <span class="py-4 text-[13px] ">{{ ('Go back to login') }}<Icon
                              name="ic:round-keyboard-arrow-right"/></span>

                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="currentLoginView == FeedPageGuestLoginView.UserInfoForm" class="flex w-full">
      <SocialLoginSignupUserInfo
          :company-id="currentCompanyDetails?.id"
          :user="loggedInUser"
          :session-ids="currentSessionIds"
          @user-info-update-success="onUserAdditionalInfoUpdateComplete"
          @on-back-press="showContinueWithGoogle"
          :also-save-as-student="alsoSaveAsStudent"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {defineProps, onMounted} from "vue";
import {signupStore} from "~/store/signupStore";
import {clubStore} from "~/store/clubStore";
import {companyStore} from "~/store/companyStore";
import {userStore} from "~/store/userStore";
import {authenticationStore} from "~/store/authenticationStore";
import {storeToRefs} from "pinia";
import {useGlobalEvent, useNotificationEvent} from "~/composables/useEventBus";
import type {User} from "~/models/user";
import {FeedPageGuestLoginView} from "~/utils/enums/FeedPageGuestLoginView";
import type {NotificationDetails} from "~/models/notificationDetails";
import {NotificationType} from "~/utils/enums/NotificationType";
import {getHiddenPhone, getPhoneWithCountryCode, isValidEmail, validateEmail} from "~/utils/helpers";
import type {AddUpdateUserRb} from "~/models/request/addUpdateUserRb";
import type {PasswordLoginRb} from "~/models/request/passwordLoginRb";
import MSAuthButton from "~/components/MSAuthButton.vue";
import CardTitleHeaderForMobileView from "~/components/auth-login/CardTitleHeaderForMobileView.vue";
import type {SocialLoginSignupViewProps} from "~/models/props/socialLoginSignupViewProps";
import moment from "moment";
import type {CompanyDetails} from "~/models/companyDetails";
import {sessionStore} from "~/store/sessionStore";
import {vMaska} from "maska";
import {ContinueMode} from "~/utils/enums/ContinueMode";
import GButtonCustom from "~/components/GButtonCustom.vue";
import type {AddUpdateUser} from "~/models/request/addCoHostRb";
import {UserRole} from "~/utils/enums/UserRole";


const {
  isChrome,
  isFirefox,
  isSafari,
  isIos,
  isAndroid,
  isMobile,
  isDesktopOrTablet,
  userAgent
} = useDevice();

function getDevice() {
  let device = ""
  if (isMobile && isIos) {
    device = "Iphone"
  } else if (isMobile && isAndroid) {
    device = "Android"
  } else if (isDesktopOrTablet) {
    device = "Desktop"
  }

  let browser = ""
  if (isChrome) browser = "Chrome"
  if (isFirefox) browser = "Firefox"
  if (isSafari) browser = "Safari"

  return {device, browser, userAgent}
}

const useUserStore = userStore()
const useClubStore = clubStore()
const useAuthenticationStore = authenticationStore();
const useSignupStore = signupStore();
const {getUserDetailsByEmailAndCompanyId, createUpdateUser, hasRole} = useUserStore
const {
  loginWithPassword,
  sendOTP,
  verifyOTPWithUserDetails,
  getUserDetailsByEmailFromServer,
  isUserAssignedIntoThisCompany,
  sendResetPasswordEmail,
} = useAuthenticationStore;
const {googleSignInProcessForSocialLoginSignup, microsoftSignInProcessForFeed} = useSignupStore;
const useCompanyStore = companyStore();
const useSessionStore = sessionStore();
const {getCompanyDetails, loadCompanyDetailsByCode} = useCompanyStore;
const {loadAndSetSessionsByCompanyId, getSessionIdsBySessions} = useSessionStore;
const {getActiveSessionsOfThisCompany} = storeToRefs(useSessionStore);
const {getCompanyDetailsLocal} = storeToRefs(useCompanyStore);
const {getCompanyCode} = storeToRefs(useCompanyStore);

const {
  projectModule,
  companyDetails,
  sessionIds,
  disableRegistration,
  alsoSaveAsStudent,
  showSignupLink,
  continueMode,
  onLoginPress,
  onSignupPress,
  requireAddress,
} = defineProps<SocialLoginSignupViewProps>()
const emit = defineEmits<{
  (event: 'loginOrSignUpSuccess', user: User): any,
}>();

const currentCompanyDetails = ref<CompanyDetails>(companyDetails);
const currentSessionIds = ref<number[]>(sessionIds ?? []);
const emailViewLoadingState = ref<boolean>(false)
const passwordViewLoadingState = ref<boolean>(false)
const googleSignInLoadingState = ref<boolean>(false)
const microsoftSignInLoadingState = ref<boolean>(false)
const signUpLoadingState = ref<boolean>(false)
const matchingOtpState = ref<boolean>(false)
const sendOtpLoadingState = ref<boolean>(false)
const sendOtpDisabledState = ref<boolean>(false)
const resetPasswordLoadingState = ref<boolean>(false)
const router = useRouter();
const currentLoginView = ref<FeedPageGuestLoginView>(FeedPageGuestLoginView.LoginWithGoogle)
const open = ref<boolean>(false);
let firstName = ref<string>('');
let lastName = ref<string>('');
let email = ref<string>('');
let profilePic = ref<string>('');
let phone = ref<string>('');
let password = ref<string>('');
let confirmPassword = ref<string>('');
let otp = ref<string>('');
let otpRecipients = ref<string>('');
let otpType = ref<string>('');
const passwordValid = ref<boolean>(true);
const otpValid = ref<boolean>(true);
const resendOtpButtonLoadingState = ref<boolean>(false)
const fromSignUpOTPMatch = ref<boolean>(false)
const deviceDetails = ref<any>({})
const otpField = ref<any>()
const modeChanging = ref<boolean>(false);
const selectedContinueMode = ref<ContinueMode>(continueMode ?? ContinueMode.Login);
let loggedInUser: User;
const showPassword = ref<boolean>(false)
const togglePassword = () => {
  showPassword.value = !showPassword.value;
}

const onLoginPressed = () => {
  if (onLoginPress) {
    onLoginPress();
  } else {
    modeChanging.value = true;
    setTimeout(()=>{
      modeChanging.value = false;
      selectedContinueMode.value = ContinueMode.Login;
    }, 1000)
  }
}
const onSignupPressed = () => {
  if (onSignupPress) {
    onSignupPress();
  } else {
    modeChanging.value = true;
    setTimeout(()=>{
      modeChanging.value = false;
      selectedContinueMode.value = ContinueMode.Signup;
    }, 1000)
  }
}

function cancel() {
  open.value = false;
}

function showContinueWithGoogle() {
  currentLoginView.value = FeedPageGuestLoginView.LoginWithGoogle;
}

function showContinueWithOther() {
  currentLoginView.value = FeedPageGuestLoginView.LoginWithEmail;
}

function goToSignupPage() {
  console.log(getCompanyCode.value)
  router.push({path: '/register/' + getCompanyCode.value})
}

function showContinueWithPassword() {
  currentLoginView.value = FeedPageGuestLoginView.LoginWithPassword;
}

function showContinueWithSignup() {
  if (isRegistrationEnabledWithWarning()) {
    currentLoginView.value = FeedPageGuestLoginView.LoginWithSignup;
  }
}

function showMatchOtp() {
  currentLoginView.value = FeedPageGuestLoginView.MatchOtp;

  if (otpField.value) {
    otpField.value.focus()
  }
}

const getCompanyDetailsAndSessionsIfNotProvided = async (user: User) => {
  if (currentCompanyDetails.value?.id < 1) {
    const userCompanyId = user.CompanyId ?? -1
    const response = await getCompanyDetails(userCompanyId);
    await loadCompanyDetailsByCode(response['code'], true);
    await loadAndSetSessionsByCompanyId(userCompanyId);
    currentCompanyDetails.value = getCompanyDetailsLocal.value;
    currentSessionIds.value = getSessionIdsBySessions(getActiveSessionsOfThisCompany.value);
  }
}

const validateAndSubmitForm = async () => {
  emailViewLoadingState.value = true;
  email.value = email.value.toLowerCase();
  try {
    if (email.value && isValidEmail(email.value ?? '')) {
      let result = await getUserDetailsByEmailFromServer(email.value, false, "", deviceDetails.value);
      const user = result['Result'] as User;
      if (user?.cellphone) {
        let trimmedPhone = user.cellphone?.toString().replaceAll('+', '') ?? '';
        if (trimmedPhone.startsWith('1') && trimmedPhone.length > 10) {
          trimmedPhone = trimmedPhone.substring(1);
        }
        phone.value = trimmedPhone;
      }
      getCompanyDetailsAndSessionsIfNotProvided(user).then();
      if (result.IsResult && result.IsOperationSuccessful) {
        showContinueWithPassword()
      } else {
        showContinueWithSignup()
      }
    } else {
      useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
        type: NotificationType.WARNING,
        title: 'Invalid Email',
        message: 'Please enter a valid email address',
      })
    }
  } catch (e) {
    console.log(e);
  }
  emailViewLoadingState.value = false;
}

async function onMicrosoftSignInSuccess(user: any) {
  microsoftSignInLoadingState.value = true;
  try {
    //Google sign in for all type of user
    if (user.length > 0) {
      const decisionMap = await microsoftSignInProcessForFeed(user[0], true, (projectModule + " Login"), deviceDetails.value);
      //If user exists, follow the path/go to dashboard
      if (decisionMap?.foundUser) {
        const user = decisionMap.foundUser as User;
        await getCompanyDetailsAndSessionsIfNotProvided(user);
        await isUserAssignedIntoThisCompany(user, currentCompanyDetails.value!.id, true)
        authComplete(user);
      }
      //User not found, new register, update field values
      else {
        if (isRegistrationEnabledWithWarning()) {
          //Create user
          //call auth complete
          const userInfo = decisionMap?.user ?? <any>{};
          const brokenName = userInfo.name.split(' ');
          const tmpFirstName = brokenName[0]
          let tmpLastName = '';
          if (brokenName.length > 1) {
            tmpLastName = brokenName[1]
          }
          firstName.value = tmpFirstName;
          lastName.value = tmpLastName;
          email.value = userInfo.email ?? '';
          password.value = 'technuf_' + moment().unix();
          confirmPassword.value = password.value + '';
          await validateAndSubmitSignupForm(false);
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
  microsoftSignInLoadingState.value = false;
}


async function onGoogleSignInSuccess(credentialMap: any) {
  googleSignInLoadingState.value = true;
  try {
    //Google sign in for all types of user
    if (credentialMap.credential) {
      const decisionMap = await googleSignInProcessForSocialLoginSignup(credentialMap.credential, true, (projectModule + " Login"), deviceDetails.value, true)
      //If a user exists, follow the path/go to dashboard
      if (decisionMap?.foundUser) {
        const user = decisionMap.foundUser as User;
        await getCompanyDetailsAndSessionsIfNotProvided(user);
        await isUserAssignedIntoThisCompany(user, currentCompanyDetails.value!.id, true);
        authComplete(user);
      }
      //User not found, new register, update field values
      else {
        if (isRegistrationEnabledWithWarning()) {
          //Create user
          //call auth complete
          const userInfo = decisionMap?.user ?? <any>{};
          firstName.value = userInfo.given_name ?? userInfo.name ?? '';
          lastName.value = userInfo.family_name ?? userInfo.name ?? '';
          email.value = userInfo.email ?? '';
          profilePic.value = userInfo.picture ?? '';
          password.value = 'technuf_' + moment().unix();
          confirmPassword.value = password.value + '';
          await validateAndSubmitSignupForm(false, true);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
  googleSignInLoadingState.value = false;
}

function validateSignUpForm(firstName: string, lastName: string, password: string, confirmPassword: string, phoneNumber: string, matchOTP: boolean = false) {
  try {
    let trimmedPhone = phoneNumber.trim();
    console.log(lastName)
    trimmedPhone = trimmedPhone.replaceAll('-', '');
    const validPhone = trimmedPhone.length == 10
    const firstNameValid = firstName.trim().length > 0;
    const lastNameValid = lastName.trim().length > 0;
    const passwordLengthValid = password.length > 5;
    const passwordValid = password == confirmPassword;
    if (!firstNameValid) {
      useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
        type: NotificationType.WARNING,
        title: 'Invalid First Name',
        message: 'Please enter first name',
      })
    } else if (!lastNameValid) {
      useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
        type: NotificationType.WARNING,
        title: 'Invalid Last Name',
        message: 'Please enter last name',
      })
    } else if (matchOTP && !validPhone) {
      useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
        type: NotificationType.WARNING,
        title: 'Invalid Phone',
        message: 'Please enter valid Phone',
      })
    } else if (!passwordLengthValid) {
      useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
        type: NotificationType.WARNING,
        title: 'Invalid Password',
        message: 'Minimum 6 digit password required',
      })
    } else if (!passwordValid) {
      useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
        type: NotificationType.WARNING,
        title: 'Invalid password',
        message: 'Passwords did not match',
      })
    } else {
      return true;
    }

  } catch (e) {
    console.log(e)
  }
  return false
}

async function validateAndSubmitSignupForm(matchOtp: boolean, signUpWithoutPassword: boolean = false) {
  signUpLoadingState.value = true;
  try {
    if (validateSignUpForm(firstName.value, lastName.value, password.value, confirmPassword.value, phone.value, matchOtp)) {
      const addUser: AddUpdateUser = <AddUpdateUser>{
        id: undefined,
        FirstName: firstName.value,
        LastName: lastName.value,
        Email: email.value,
        password: (signUpWithoutPassword ? undefined : password.value),
        Cellphone: (getPhoneWithCountryCode(phone.value ?? '') ?? '').toString(),
        ProfilePicture: profilePic.value,
        profilePicture: profilePic.value,
        company_id: currentCompanyDetails.value!.id,
        roleIds: [20],
        status: matchOtp ? 'Inactive' : 'Active',
        isSave: false,
        token: ''
      }


      let {data} = await createUpdateUser(addUser, true);
      if (data) {
        if (matchOtp) {
          const response = await sendOTP(email.value, currentCompanyDetails.value!.name ?? 'Aphelia');
          if (response) {
            fromSignUpOTPMatch.value = true;
            otpRecipients.value = email.value;
            otpType.value = 'email';
            showMatchOtp()
          } else {
            useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
              type: NotificationType.WARNING,
              title: 'Could not send code',
              message: 'We could not send code to your email address. Please try again later.',
            })
          }
        } else {
          const {Result} = await getUserDetailsByEmailFromServer(data.Email, true, projectModule + " Signup", deviceDetails.value);
          authComplete(Result as User);
        }
      } else {
        useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
          type: NotificationType.ERROR,
          title: 'Error',
          message: 'Could not create user. Please try again.',
        })
      }
    }
  } catch (err) {
    console.log(err)
    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
      type: NotificationType.ERROR,
      title: 'Network Error',
      message: 'Failed to sign up',
    })
  }
  signUpLoadingState.value = false;
}

async function validateAndLoginForm(email: string, password: string) {
  passwordViewLoadingState.value = true;
  try {
    const emailValid = (validateEmail(email)?.length ?? 0) > 0;
    passwordValid.value = password.length > 0;
    if (!passwordValid.value) {
      useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
        type: NotificationType.WARNING,
        title: 'Invalid Password',
        message: 'Please enter a password',
      })
    } else if (!emailValid) {
      useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
        type: NotificationType.WARNING,
        title: 'Invalid Email',
        message: 'Please enter a valid email',
      })
    } else {
      const response = await loginWithPassword(<PasswordLoginRb>{
        userName: email,
        password: password,
        deviceDetails: deviceDetails.value,
        action: projectModule + ' Login'
      });
      if (response) {
        const user = response as User;
        await isUserAssignedIntoThisCompany(user, currentCompanyDetails.value!.id, true);
        authComplete(user);
      } else {
        useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
          type: NotificationType.WARNING,
          title: 'Incorrect Email/Password',
          message: 'Your have provided incorrect email or password',
        })
      }
    }

  } catch (e) {
    console.log(e)
  }
  passwordViewLoadingState.value = false;
}

const isRegistrationEnabledWithWarning = () => {
  if (disableRegistration) {
    useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
      type: NotificationType.WARNING,
      title: 'Not Found',
      message: 'No user found with this email address.',
    })
  }
  return !disableRegistration;
}

function setupEventListener() {

}

onMounted(() => {
  setupEventListener();
  deviceDetails.value = getDevice();
})

async function onSendOtpToEmail(recipient: string, type: string = 'email', resend: boolean = false) {
  if (resend) {
    resendOtpButtonLoadingState.value = true;
  } else {
    sendOtpLoadingState.value = true;
  }
  sendOtpDisabledState.value = true;
  try {
    const emailValid = (validateEmail(recipient)?.length ?? 0) > 0;
    if (type == 'email' && !emailValid) {
      useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
        type: NotificationType.WARNING,
        title: 'Invalid Email',
        message: 'Please enter a valid email',
      })
    } else {
      if (otpField.value)
        otpField.value.focus();

      const response = await sendOTP(recipient, companyDetails.name ?? 'Aphelia', type);
      if (response) {
        otpRecipients.value = recipient;
        otpType.value = type;

        showMatchOtp()
      } else {
        useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
          type: NotificationType.WARNING,
          title: 'Could not send OTP',
          message: 'We could not send OTP to your email address. Please try again later.',
        })
      }
    }
  } catch (e) {
    console.log(e);
  }
  if (resend) {
    resendOtpButtonLoadingState.value = false;
  } else {
    sendOtpLoadingState.value = false;
  }
  sendOtpDisabledState.value = false;
}

async function validateAndSubmitOtpForm(email: string, otp: string, type: string = 'email') {
  matchingOtpState.value = true;
  try {
    const emailValid = (validateEmail(email)?.length ?? 0) > 0;
    otpValid.value = otp.length == 6;

    console.log(otpType.value)
    if (!otpValid.value) {
      useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
        type: NotificationType.WARNING,
        title: 'Invalid code',
        message: 'Please enter a valid 6 digit code',
      })
    } else if (type == 'email' && !emailValid) {
      useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
        type: NotificationType.WARNING,
        title: 'Invalid Email',
        message: 'Please enter a valid email',
      })
    } else {
      const response = await verifyOTPWithUserDetails(email, otp, fromSignUpOTPMatch.value, projectModule + ' Login', deviceDetails.value, otpType.value);
      if (response) {
        const userFound = response;
        if (userFound) {
          const currentUser = (userFound as User);
          await isUserAssignedIntoThisCompany(currentUser, currentCompanyDetails.value!.id, true);
          authComplete(currentUser);
        }
      } else {
        useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
          type: NotificationType.WARNING,
          title: 'Wrong OTP',
          message: 'OTP does not match. Please check again',
        })
      }
    }
  } catch (e) {
    console.log(e);
  }
  matchingOtpState.value = false;
}


async function validateAndSubmitResetPasswordForm(email: string) {
  resetPasswordLoadingState.value = true;
  try{
    const emailValid = (validateEmail(email)?.length ?? 0) > 0;
    if(!emailValid) {
      useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
        type: NotificationType.WARNING,
        title: 'Invalid Email',
        message: 'Please enter a valid email',
      })
    }
    else {
      const response = await sendResetPasswordEmail(email);
      if(response){
        onGoBackToResetPasswordSuccessState();
      }else{
        useGlobalEvent(useNotificationEvent(), <NotificationDetails>{
          type: NotificationType.WARNING,
          title: 'Email send error',
          message: 'Could not send reset password email to your email. Please check your email and try again',
        })
      }
    }
  }catch (e) {
    console.log(e);
  }
  resetPasswordLoadingState.value = false;
}

const authComplete = (user: User) => {
  loggedInUser = user;
  onUserAdditionalInfoUpdateComplete(user);
  // if(hasRole(user, getCompanyDetailsLocal.value?.id, UserRole.ROLE_SC_ADMIN)){
  // }else{
  //   currentLoginView.value = FeedPageGuestLoginView.UserInfoForm;
  // }
}

const onUserAdditionalInfoUpdateComplete = (user: User) => {
  emit('loginOrSignUpSuccess', user);
}

const goBackToEnterEmailState = () => {
  currentLoginView.value = FeedPageGuestLoginView.LoginWithEmail;
}

const onGoBackToResetPasswordSuccessState = () => {
  currentLoginView.value = FeedPageGuestLoginView.ResetPasswordSuccess;
}

const onGoToResetPasswordState = () => {
  currentLoginView.value = FeedPageGuestLoginView.ResetPasswordEmail;
}
</script>

<style scoped>

</style>
